import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from '@ionic/angular';

@Component({
  selector: "app-platform-upgrade-available",
  templateUrl: "./platform-upgrade-available.component.html",
  styleUrls: ["./platform-upgrade-available.component.scss"],
})
export class PlatformUpgradeAvailableComponent implements OnInit {
  @Input() isForcedToUpdate: boolean;
  constructor(private modalCtrl: ModalController) {
  }

  ngOnInit() { }

  updateAndroid() {
    this.modalCtrl.dismiss({
      confirm: true
    });
  }
  cancelar() {
    this.modalCtrl.dismiss()
  }
}
