import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumeroDni]'
})
export class NumeroDniDirective {

  constructor() { }

  @HostListener('keypress',['$event']) validar(event: KeyboardEvent)
  {
    console.log(event);    
    console.log(event.key);    
    return event.key;
  }

}
