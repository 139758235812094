import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AlertConectionComponent } from './alert-conection/alert-conection.component';
import { FooterTabComponent } from './footer-tab/footer-tab.component';
import { MyHeaderComponent } from './my-header/my-header.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { IonicModule } from '@ionic/angular';
import { ConfirmarEvaluacionComponent } from './confirmar-evaluacion/confirmar-evaluacion.component';
import { CursosListModalComponent } from './cursos-list-modal/cursos-list-modal.component';
import { SideMenuMobileComponent } from './side-menu-mobile/side-menu-mobile.component';
import { SelectBoxComponent } from './ui/select-box-component/select-box/select-box.component';
import { OptionSelectBoxComponent } from './ui/select-box-component/option-select-box/option-select-box.component';
import { CountdownTimerComponent } from './countdown-timer/countdown-timer.component';
import { ContenidoMediaComponent } from './contenido-media/contenido-media.component';
import { PopoverLeaveComponent } from './popover-leave/popover-leave.component';
import { NpsModalComponent } from './nps-modal/nps-modal.component';
import { PlatformAvailableComponent } from './platform-modal/platform-available/platform-available.component';
import { PlatformUpgradeAvailableComponent } from './platform-modal/platform-upgrade-available/platform-upgrade-available.component';
import { PopoverComponent } from './popover/popover.component';
import { PopoverInfoComponent } from './popover-info/popover-info.component';
import { ToastComponent } from './toast-message/toast.component';
import { BasicModalComponent } from './basic-modal/basic-modal.component';
import { PopupMenuComponent } from './popup-menu/popup-menu.component';
import { IconSvgComponent } from './ui/icon-svg/icon-svg.component';
import { SelectMobileModalComponent } from './select-mobile-modal/select-mobile-modal.component';
import { PrimaryButtonComponent } from './buttons/primary-button/primary-button.component';
import { TertiaryButtonComponent } from './buttons/tertiary-button/tertiary-button.component';
import { SecondaryButtonComponent } from './buttons/secondary-button/secondary-button.component';
import { NotificationModalComponent } from './notification-modal/notification-modal.component';
import { BasicDrawComponent } from './basic-draw/basic-draw.component';

import { ClickedOutsideModule } from '../directives/clicked-outside.directive';
import { DropdownComponent } from './dropdown/dropdown.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { ImageSvgComponent } from './ui/image-svg/image-svg.component';
import { EventTrackerModule } from '../directives/event-tracker.directive';
import { BasicMapComponent } from './basic-map/basic-map.component';
@NgModule({
  declarations: [
    AlertConectionComponent,
    FooterTabComponent,
    MyHeaderComponent,
    ProgressBarComponent,
    SideMenuComponent,
    ConfirmarEvaluacionComponent,
    CursosListModalComponent,
    SideMenuMobileComponent,
    SelectBoxComponent,
    OptionSelectBoxComponent,
    CountdownTimerComponent,
    ContenidoMediaComponent,
    PopoverLeaveComponent,
    NpsModalComponent,
    PlatformAvailableComponent,
    PlatformUpgradeAvailableComponent,
    PopoverComponent,
    PopoverInfoComponent,
    ToastComponent,
    BasicModalComponent,
    PopupMenuComponent,
    IconSvgComponent,
    SelectMobileModalComponent,
    PrimaryButtonComponent,
    SecondaryButtonComponent,
    TertiaryButtonComponent,
    NotificationModalComponent,
    BasicDrawComponent,
    DropdownComponent,
    TimePickerComponent,
    ImageSvgComponent,
    BasicMapComponent
  ],
  exports: [
    AlertConectionComponent,
    FooterTabComponent,
    MyHeaderComponent,
    ProgressBarComponent,
    SideMenuComponent,
    ConfirmarEvaluacionComponent,
    CursosListModalComponent,
    SideMenuMobileComponent,
    SelectBoxComponent,
    OptionSelectBoxComponent,
    CountdownTimerComponent,
    ContenidoMediaComponent,
    PopoverLeaveComponent,
    NpsModalComponent,
    PlatformAvailableComponent,
    PlatformUpgradeAvailableComponent,
    PopoverComponent,
    PopoverInfoComponent,
    ToastComponent,
    BasicModalComponent,
    PopupMenuComponent,
    IconSvgComponent,
    SelectMobileModalComponent,
    PrimaryButtonComponent,
    SecondaryButtonComponent,
    TertiaryButtonComponent,
    NotificationModalComponent,
    BasicDrawComponent,
    DropdownComponent,
    TimePickerComponent,
    ImageSvgComponent,
    BasicMapComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    ClickedOutsideModule,
    EventTrackerModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule { }
