// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	APP_MULTIMARCA: false,
	URL_MASTER: "https://test.master.cursalab.io",

	//**MULTIMARCA
	// SERVICE_BASE: "",
	// SERVICE_BASE_GESTOR: "",
	// SERVICE_BASE_REST: "",
	// SERVICE_BASE_AUTH: "",
	// URL_BASE: "",
	// URL_REPORTE: "",

	//**url de tiendas multimarca
	APP_PLAYSTORE: "https://play.google.com/store/apps/details?id=io.cursalab.app&hl=es",
	APP_ID_PLAYSTORE: "io.cursalab.app",
	APP_ID_IOS: "",
	//**versiones de multimarca
	APP_VERSION_IOS: "0.0.0",
	APP_VERSION_ANDROID: "0.5",
	//***** google analytics ID MULTIMARCA
	// GA_TRACKING_ID:"G-PG6YEHQR59",

	//**MARCA PROPIA
	// SERVICE_BASE: "https://gestiona.test2.cursalab.co/api/",
	// SERVICE_BASE_GESTOR: "https://gestiona.test2.cursalab.co/",
	// SERVICE_BASE_REST: "https://gestiona.test2.cursalab.co/api/rest/",
	// SERVICE_BASE_AUTH: "https://gestiona.test2.cursalab.co/api/auth/",
	// URL_BASE: "https://test2.cursalab.co/",
	// URL_REPORTE: "https://reportes.main.cursalab.co/",

	// SERVICE_BASE: "https://test.inretail.cursalab.io/api/",
	// SERVICE_BASE_GESTOR: "https://test.gestiona.inretail.cursalab.io/",
	// SERVICE_BASE_REST: "https://test.inretail.cursalab.io/api/rest/",
	// SERVICE_BASE_AUTH: "https://test.inretail.cursalab.io/api/auth/",
	// URL_BASE: "https://test.inretail.cursalab.io/",
	// URL_REPORTE: "https://test.api.reports.inretail.cursalab.io/",

	SERVICE_BASE: "https://gestiona.induccion.cursalab.co/api/",
	SERVICE_BASE_GESTOR: "https://gestiona.induccion.cursalab.co/",
	SERVICE_BASE_REST: "https://gestiona.induccion.cursalab.co/api/rest/",
	SERVICE_BASE_AUTH: "https://gestiona.induccion.cursalab.co/api/auth/",
	URL_BASE: "https://test.inretail.cursalab.io/",
	URL_REPORTE: "https://test.api.reports.inretail.cursalab.io/",

	// SERVICE_BASE: "https://cursalab-gestor-capacitacion.test/api/",
	// SERVICE_BASE_GESTOR: "https://cursalab-gestor-capacitacion.test/",
	// SERVICE_BASE_REST: "https://cursalab-gestor-capacitacion.test/api/rest/",
	// SERVICE_BASE_AUTH: "https://cursalab-gestor-capacitacion.test/api/auth/",
	// URL_BASE: "https://test.inretail.cursalab.io/",
	// URL_REPORTE: "https://test.api.reports.inretail.cursalab.io/",

	// SERVICE_BASE: "https://inretail.cursalab.io/api/",
	// SERVICE_BASE_GESTOR: "https://gestiona.inretail.cursalab.io/",
	// SERVICE_BASE_REST: "https://inretail.cursalab.io/api/rest/",
	// SERVICE_BASE_AUTH: "https://inretail.cursalab.io/api/auth/",
	// URL_BASE: "https://inretail.cursalab.io/",
	// URL_REPORTE: "https://api.reports.inretail.cursalab.io/",

	// SERVICE_BASE: "https://test2.inretail.cursalab.io/api/",
	// SERVICE_BASE_GESTOR: "https://test2.gestiona.inretail.cursalab.io/",
	// SERVICE_BASE_REST: "https://test2.inretail.cursalab.io/api/rest/",
	// SERVICE_BASE_AUTH: "https://test2.inretail.cursalab.io/api/auth/",
	// URL_BASE: "https://test2.inretail.cursalab.io/",
	// URL_REPORTE: "https://test.api.reports.inretail.cursalab.io/",

	// SERVICE_BASE: "https://test.gestiona.demo.cursalab.io/api/",
	// SERVICE_BASE_GESTOR: "https://test.gestiona.demo.cursalab.io/",
	// SERVICE_BASE_REST: "https://test.gestiona.demo.cursalab.io/api/rest/",
	// SERVICE_BASE_AUTH: "https://test.gestiona.demo.cursalab.io/api/auth/",
	// URL_BASE: "https://test.demo.cursalab.io/",
	// URL_REPORTE: "https://demo.api.reports.cursalab.io/",

	// SERVICE_BASE: "https://migra.gestiona.inretail.cursalab.io/",
	// SERVICE_BASE_REST: "https://migra.gestiona.inretail.cursalab.io/api/rest/",
	// SERVICE_BASE_AUTH: "https://migra.gestiona.inretail.cursalab.io/api/auth/",
	// URL_BASE: "https://migra.inretail.cursalab.io/",
	// URL_REPORTE: "https://migra.api.reports.inretail.cursalab.io/",

	// SERVICE_BASE: "https://aux.gestiona.inretail.cursalab.io/",
    // SERVICE_BASE_REST: "https://aux.gestiona.inretail.cursalab.io/api/rest/",
    // SERVICE_BASE_AUTH: "https://aux.gestiona.inretail.cursalab.io/api/auth/",
    // URL_BASE: "https://aux.inretail.cursalab.io/",
    // URL_REPORTE: "https://aux.api.reports.inretail.cursalab.io/",

	SERVICE_BASE_REST_MASTER: "https://test.master.cursalab.pe/api/",

	//**url de tiendas IR*/
	// APP_ID_IOS: "xxxxxxxx",
	// APP_ID_PLAYSTORE: "io.cursalab.weconnect",
	// APP_PLAYSTORE: "https://play.google.com/store/apps/details?id=io.cursalab.weconnect&hl=es",
	// APP_STORE_HUAWEI: "https://urldra.cloud.huawei.com/iNnYOY7FU4",

	// //APP_STORE_WEB: "https://gestiona.inretail.cursalab.io/informacion_app",

	//**versiones de IR
	// APP_VERSION_IOS: "0.0.0",
	// APP_VERSION_ANDROID: "4.3",

	//***** google analytics ID IR
	// GA_TRACKING_ID:"G-BZR1WGFYRV",


	//*****google analytics ID test
	GA_TRACKING_ID:"G-FVFVVW99KV",

	recaptcha:{
		siteKey: "6LeLUBAkAAAAAGIuPtfdvgzcpgAPHboYlvp1GdUf"
	},

	RUTA_ARCHIVOS: "archivos/",
	RUTA_DIPLOMA: "dnc/",
	RUTA_DESCARGAS: "dnx/",

	LOGO_APP: "assets/img/logo_color.png",
	LOGO_BY_CURSALAB: "assets/img/logo_by_cursalab.svg",
	LOGO_CURSALAB: "assets/img/logo-cursalab.svg",

	RUTA_TEMPLATE_INVITADOS:
		"https://d1rhexnbhr0x6m.cloudfront.net/inretail/plantillas/Plantilla-Importar-Invitados.xlsx",

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
