import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-select-mobile-modal',
  templateUrl: './select-mobile-modal.component.html',
  styleUrls: ['./select-mobile-modal.component.scss'],
})
export class SelectMobileModalComponent implements OnInit {
  title: string = "";
  optionsList: string[];
  constructor(private navParams: NavParams, public modalController: ModalController) { }

  ngOnInit() {
    this.title = this.navParams.get('title');
    this.optionsList = this.navParams.get('optionsList');
  }
  async onSelectedOption(selectedValue: any) {
    await this.modalController.dismiss({ selectedValue });
  }

}
