import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Subscription, timer, BehaviorSubject } from "rxjs";
import { UsuarioServiceProvider } from "../index.providers";
import { ApiService } from "src/app/api.service";
@Injectable({
	providedIn: "root",
})
export class NotificacionService {
	private notificacionObservable: Subscription;
	public new_notification = new EventEmitter<boolean>();

	private _notification: boolean = false;
	public showNotification = new BehaviorSubject<boolean>(this._notification);
	currentHideNotification = this.showNotification.asObservable();

	private _listNotification: boolean = false;
	public listNotification = new BehaviorSubject<boolean>(this._listNotification);
	listNotifications = this.listNotification.asObservable();

	public newNotification = new BehaviorSubject<boolean>(false);
  	newNotifications = this.newNotification.asObservable();

	loadNotification: boolean = true;

	constructor(private http: HttpClient, private up: UsuarioServiceProvider, private api: ApiService) {
		this.up.isLogged.subscribe((res) => {
			if (res) {
				this.changeListNotification(true, null);
			} else {
				if (this.notificacionObservable) this.killNotificaciones();
			}
		});
	}

	changeShowNotification(state:boolean) {
    this.showNotification.next(state);
  }

	changeListNotification(state:boolean, data:any) {
		if(state){
			if(this.loadNotification){
				this.loadNotification = false;
				this.api.listNotifications().then(res => {
					this.listNotification.next(res);
					const newN = res.some(e => e.notifications.some(notification => !notification.is_read && notification.is_visible ));
					this.newNotification.next(newN ? true : false);
					setTimeout(() => {
						this.loadNotification = true;
					}, 300000);
				}).catch((err) => {
					this.loadNotification = true;
				})
			}
		}
		else {
			this.listNotification.next(data);
			const newN = data.some(e => e.notifications.some(notification => !notification.is_read && notification.is_visible ));
			this.newNotification.next(newN? true : false);
		}
  }

	pollNotificaciones() {
		// this.notificacionObservable = timer(0, 120000).subscribe(() => {
		//   this.ObtenerNotificaciones().then(res => {
		//     this.new_notification.next(res.unread.data.length > 0 ? true : false)
		//   })
		// })
	}

	killNotificaciones() {
		this.notificacionObservable.unsubscribe();
	}
}
