import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ArrayrandonPipe } from './arrayrandom/arrayrandon.pipe';
import { KeysobjectPipe } from './Keysobject/keysobject.pipe';
import { ZoomUrl } from '../pipes/zoomurl/zoomurl';
import { DateAlumnoPipe } from './date-alumno/date-alumno.pipe';
import { HtmlRenderPipe } from './htmlRender/html-render.pipe';

@NgModule({
  declarations: [
    ArrayrandonPipe,
    KeysobjectPipe,
    ZoomUrl,
    DateAlumnoPipe,
    HtmlRenderPipe
  ],
  exports: [
    ArrayrandonPipe,
    KeysobjectPipe,
    ZoomUrl,
    DateAlumnoPipe,
    HtmlRenderPipe
  ],
  imports: [
    CommonModule,
    IonicModule,
    // ZoomUrl
  ]
})
export class PipesModule { }
