import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { delay } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PrinActivoServiceService {

  private _prin_activo: any[] = [];
  
  public get prin_activo(): any[] {
    return this._prin_activo;
  }
  public set prin_activo(value: any[]) {
    this._prin_activo = value;
  }

  getPorts(page?: number, size?: number): any[] {
    let activo = [];
   // console.log("prin activo:::",this._prin_activo);

    this._prin_activo.forEach(e => {
      activo.push(e);
    });

    // if (page && size) {
    //   activo = activo.slice((page - 1) * size, ((page - 1) * size) + size);
    // }
    return activo;
  }

  getPortsAsync(page?: number, size?: number, timeout = 1000): Observable<any[]> {
    return new Observable<any[]>(observer => {
      observer.next(this.getPorts(page, size));
      observer.complete();
    }).pipe(delay(timeout));
  }
}
