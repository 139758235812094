import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";
import { UsuarioServiceProvider } from "../usuario-service/usuario-service";
@Injectable({ providedIn: "root" })
export class ZoomServiceProvider {
	constructor(
		public http: HttpClient,
		private storage: Storage,
		private up: UsuarioServiceProvider
	) {
		//this.load_storage();
	}

	list_reuniones_usuarios_get() {
		let url: string = this.up.getRutaRest + "eventos/user-events";
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		console.log(promesa);
		return promesa;
	}

	descargarExcel() {
		let url: string = this.up.getRutaRest + "eventos/eventos-monitor";
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		console.log(promesa);
		return promesa;
	}

	api_consultar_disponibilidad(fecha_reunion, hora_reunion, duracion) {
		let url: string = this.up.getRutaRest + "eventos/reserve";

		let json_data = {
			fecha: fecha_reunion.toString(),
			hr_inicio: hora_reunion.toString(),
			duracion: duracion.toString(),
		};

		let promesa = this.http
			.post(url, JSON.stringify(json_data))
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		console.log(promesa);
		return promesa;
	}

	editar_api_consultar_disponibilidad(evento_id, fecha_reunion, hora_reunion, duracion) {
		let url: string = this.up.getRutaRest + "eventos/update-date/" + evento_id;

		let json_data = {
			fecha: fecha_reunion.toString(),
			hr_inicio: hora_reunion.toString(),
			duracion: duracion.toString(),
		};

		let promesa = this.http
			.post(url, JSON.stringify(json_data))
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		console.log(promesa);
		return promesa;
	}

	save_storage_token_zoom(token: any) {
		this.storage.set("token_cuenta_zoom", token);
		this.storage.get("id_usuario").then((token_cuenta) => {
			console.log(token_cuenta);
		});
	}

	list_usuarios_get(
		nombre_dni: any = null,
		fecha_inicio: any,
		fecha_fin: any,
		filtro_botica: any,
		filtro_nombre: any,
		filtro_dni: any,
		creador_id: any,
		getData_Grupos: any
	) {
		console.log(nombre_dni, fecha_fin, fecha_fin, filtro_botica, filtro_nombre, filtro_dni);
		let url: string = this.up.getRutaRest + "eventos/busqueda-asistentes";

		let json_data = {
			filtro: nombre_dni,
			fecha_inicio: fecha_inicio.toString(),
			fecha_fin: fecha_fin.toString(),
			filtro_botica: filtro_botica.toString(),
			filtro_nombre: filtro_nombre.toString(),
			filtro_dni: filtro_dni.toString(),
			creador_id: parseInt(creador_id),
			grupo: getData_Grupos,
		};

		let promesa = this.http
			.post(url, JSON.stringify(json_data))
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		console.log(promesa);
		return promesa;
	}

	crear_evento_bd(
		id_user: any,
		evento_id: any,
		cuenta_zoom_id: any,
		link_zoom: any,
		tipo_event_id: any,
		duracion: any,
		fecha_reunion: any,
		hora_reunion: any,
		titulo_reunion: any,
		descripcion_reunion: any = null,
		data_completa: any,
		link_google_form: any = null
	) {
		let url: string = this.up.getRutaRest + "eventos/update/" + evento_id;

		let json_data = {
			descripcion: descripcion_reunion,
			tipo_evento_id: tipo_event_id.toString(),
			titulo: titulo_reunion.toString(),
			asistentes: data_completa,
			link_google_form: link_google_form,
		};

		let promesa = this.http
			.post(url, JSON.stringify(json_data))
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	editar_evento(
		evento_id: any,
		titulo: any,
		descripcion: any = null,
		link_google_form: any = null,
		fecha_inicio: any,
		duracion: any,
		asistentes: any = null,
		cambio_fecha: any,
		cambio_datos: any,
		cambio_asistentes: any,
		tipo_event_id: any
	) {
		let url: string = this.up.getRutaRest + "eventos/update/" + evento_id;

		let json_data = {
			titulo: titulo.toString(),
			tipo_evento_id: tipo_event_id.toString(),
			descripcion: descripcion,
			link_google_form: link_google_form,
			fecha_inicio: fecha_inicio.toString(),
			duracion: duracion.toString(),
			asistentes: asistentes,
			cambio_fecha: cambio_fecha,
			cambio_datos: cambio_datos,
			cambio_asistentes: cambio_asistentes,
		};

		let promesa = this.http
			.post(url, JSON.stringify(json_data))
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	cambiarEstadoEvento(evento_id: any, estado: any) {
		let url: string = this.up.getRutaRest + "eventos/set-estado";

		let json_data = {
			evento_id: evento_id.toString(),
			estado: estado.toString(),
		};

		let promesa = this.http
			.post(url, JSON.stringify(json_data))
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	consultar_asistentes_evento(evento_id: any) {
		let url: string = this.up.getRutaRest + "eventos/asistentes/" + evento_id;

		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	eliminareventoPreReservado(evento_id: any) {
		let url: string = this.up.getRutaRest + "eventos/eliminar-pre-reservado/" + evento_id;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		console.log(promesa);
		return promesa;
	}

	eliminar_evento(evento_id: any) {
		let url: string = this.up.getRutaRest + "eventos/delete/" + evento_id;

		let promesa = this.http
			.post(url, {})
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	// /listado_grupos
	getData_Grupos() {
		let url: string = this.up.getRutaRest + "eventos/grupos";
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		console.log(promesa);
		return promesa;
	}

	validarExcelAsistentes(dni: any, fecha_inicio: any, fecha_fin: any, creador_id: any) {
		let url: string = this.up.getRutaRest + "eventos/validar-excel-asistentes";

		let json_data = {
			invitados: dni,
			fecha_inicio: fecha_inicio.toString(),
			fecha_fin: fecha_fin.toString(),
		};

		let promesa = this.http
			.post(url, JSON.stringify(json_data))
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	estado_evento(evento_id) {
		let url: string = this.up.getRutaRest + "eventos/estado/" + evento_id;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		console.log(promesa);
		return promesa;
	}

	terminarReunion(evento_id: any) {
		let url: string = this.up.getRutaRest + "eventos/end-meeting/" + evento_id;

		let promesa = this.http
			.post(url, {})
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}
}
