import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({ providedIn: "root" })
export class CategoriaServiceProvider {
	private data_categorias: any[] = [];
	private data_cursos: any[] = [];
	private data_carrera: any[] = [];

	constructor(public http: HttpClient) {}
	/**
	 * Getters and Setters
	 */

	public get getCategorias(): any {
		return this.data_categorias;
	}
	public set setCategorias(categorias: any) {
		this.data_categorias = categorias;
	}
	public get getCursos(): any {
		return this.data_cursos;
	}
	public set setCursos(cursos: any) {
		this.data_cursos = cursos;
	}
	public get getCarrera(): any {
		return this.data_carrera;
	}
	public set setCarrera(carrera: any) {
		this.data_carrera = carrera;
	}
}
