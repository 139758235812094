import { Component, Inject, OnInit } from "@angular/core";
import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { UsuarioServiceProvider, FuncionesServiceProvider } from "./service/index.providers";
import { Router, NavigationEnd } from "@angular/router";
import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx";
import { Storage } from "@ionic/storage";
import { NavController } from "@ionic/angular";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { LocalNotifications } from "@ionic-native/local-notifications/ngx";
import { Keyboard } from "@ionic-native/keyboard/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
// import { FirebaseConfig } from '@ionic-native/firebase-config/ngx';
import { environment } from "../environments/environment";
import { MenuServiceProvider } from 'src/app/service/index.providers';
import { NotificacionService } from "src/app/service/notificaciones-service/notificacion.service";
import { filter } from "rxjs/operators";
import { Title } from '@angular/platform-browser';
import { ApiService } from "../app/api.service";

import { DOCUMENT } from '@angular/common';

import * as moment from "moment";


declare const gtag: Function; 

@Component({
	selector: "app-root",
	templateUrl: "app.component.html",
	styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {

	public rootPage: any;
	keyboardHidden = true;
	SDK_KEY = "8da6o4WWAQrFYQNrkK4d5xXaaEHjY0L1BzW3";
	SDK_SECRET = "Nf1EVzndUCF0JGIjtpNZVcpsOabERp7vB4BF";
	fullscreen: boolean = false;
	userID: any = null;
	domain: any = null;
	platformIsIOS: boolean | null = null;
	showImpersonationBar:boolean = false;
	impersonationBarTitle;

	config_empresa: any = null;
	showNotification: boolean = true;
	data_usuario;
	initials;
	userFullName:string = "";

	//**config Cursalab del espacio multimarca**
	defaultConfigData = {
	color_primario_app: "#5458e9",
	color_secundario_app: "#58c1e5",
	color_terciario_app: "#372db4",
	titulo_login_app: "Bienvenido a",
	subtitulo_login_app: "Cursalab",
	logo_cursalab_position: "top-left",
	form_login_position: "center",
	form_login_transparency: 100,
	show_blog_btn: 1,
	female_logo: 'assets/img/avatar_mujer.svg',
	male_logo: 'assets/img/avatar_hombre.svg',
	fondo_app: 'assets/img/bg-cursalab.png',
	enrolled_courses_logo: 'assets/icon/cursos_inscritos.svg',
	completed_courses_logo:'assets/icon/cursos_completados.svg',
	diplomas_logo: 'assets/icon/diplomas_obtenidas.svg',
	icono: "assets/icon/favicon.png",
	id: 1,
	link_pbi: null,
	logo_cursalab: 'assets/img/logo_by_cursalab.svg',
	logo_app: 'assets/img/logo-cursalab.svg',
	titulo: "Cursalab",
	titulo_login: "Cursalab",
	url_app_web: null,
	template: "template-default"
	}

	constructor(
		public platform: Platform,
		private titleService: Title,
		private splashScreen: SplashScreen,
		private up: UsuarioServiceProvider,
		private fp: FuncionesServiceProvider,
		public router: Router,
		private fcm: FCM,
		private Storage: Storage,
		public navCtrl: NavController,
		private iab: InAppBrowser,
		private LocalNotifications: LocalNotifications,
		private mp: MenuServiceProvider,
		private keyboard: Keyboard, // private fireConfig: FirebaseConfig
		private api: ApiService,
		private statusBar: StatusBar,
		private ns: NotificacionService,
		@Inject(DOCUMENT) private document: any
	) {
		//console.log("production?: ", environment.production);
		this.domain = this.document.location.hostname;		
		this.addGoogleAnalyticsScript();
		// if (platform.is('cordova')) {
		//   this.fireConfig.getBoolean('mantenimiento').then(res => {
		//     console.log("Mantenimiento", res);
		//   })
		// }

		this.mp.currentHideFooter.subscribe((state) => (this.fullscreen = state));

		this.ns.currentHideNotification.subscribe((state) => (this.showNotification = state));

		this.keyboard.onKeyboardDidHide().subscribe(() => {
			this.keyboardHidden = true;
		});

		this.keyboard.onKeyboardWillShow().subscribe(() => {
			this.keyboardHidden = false;
		});

		this.up.isLogged.subscribe((res) => {
			this.data_usuario = JSON.parse(localStorage.getItem("user_data"));
			if (this.data_usuario) {
				this.initials = this.data_usuario.nombre.charAt(0).toUpperCase() + this.data_usuario.apellido.charAt(0).toUpperCase();
				this.userFullName =  this.data_usuario.full_name;
			}
			
			if (res) {		
				this.fp.cargarFunciones();
				this.userID = this.up.getIdUsuario;
				//validate impersonation session  
		 		const configData = JSON.parse(localStorage.getItem("config_data"));
				if(configData.impersonation) {
					setTimeout(() => {
						this.showImpersonationBar = configData.impersonation.show_bar;
						this.impersonationBarTitle = configData.impersonation.show_title;
					}, 1000);
				}
			} else {
				this.showImpersonationBar = false;
				this.impersonationBarTitle = "";
			}
		});

		//Add dynamic title for selected pages
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				let title:string | any = this.getTitle(this.router.routerState, this.router.routerState.root);
				if (this.config_empresa) {					
					title.length > 0
					? titleService.setTitle(this.config_empresa.titulo + " | " + title[0])
					: titleService.setTitle(this.config_empresa.titulo);
				}	
				//title.length > 0 ? titleService.setTitle( 'WeConnect 2.0 | ' + title[0]) :  titleService.setTitle( 'WeConnect 2.0');
				if(title.length > 0 && title[0] !== "login") {					
					const element = document.getElementsByClassName("grecaptcha-badge")[0] as HTMLElement;
					if (element) {
						element.style.visibility = "hidden";
					}
				};

				const user = JSON.parse(localStorage.getItem("user_data"));
				if (user) {
					const nps = localStorage.getItem("nps") ? JSON.parse(localStorage.getItem("nps")) : null;
					const date = localStorage.getItem("date_nps")
						? JSON.parse(localStorage.getItem("date_nps"))
						: { query: null, sent: null };
					const date_now = moment(new Date()).format("L");
					if (!nps || (nps && date.query !== date_now)) {
						const dni = user.dni;
						this.api
							.listNPS("app", "2", dni)
							.then((res: any) => {
								const date_nps = { query: date_now, sent: null };
								if (date.sent === date_now) {
									date_nps.sent = date.sent;
								}
								localStorage.setItem("date_nps", JSON.stringify(date_nps));
								localStorage.setItem("nps", JSON.stringify(res.data));
							})
							.catch((err) => {
								console.log(err);
								localStorage.setItem("nps", JSON.stringify([]));
							});
					}
				}
				this.ns.changeShowNotification(false);
			}
		});

		//Add analytics
		if (this.domain != "localhost") {
			const navEndEvents$ = this.router.events.pipe(
				filter((event) => event instanceof NavigationEnd)
			);
			
			navEndEvents$.subscribe((event: NavigationEnd) => {
					if (this.userID) {
						gtag("config", environment.GA_TRACKING_ID, {
							user_id: this.userID,
							page_path: event.urlAfterRedirects,
						});
					} else {
						gtag("config", environment.GA_TRACKING_ID);
						gtag("event", "page_view", {
							page_path: event.urlAfterRedirects,
						});
					}
			});			
		}


		this.platform.ready().then(() => {			
			if (this.platform.is("ios")) {
				this.platformIsIOS = true;
				this.PermissionIOS();
				// let status bar overlay webview
				this.statusBar.overlaysWebView(false);
				// set status bar to white
				this.statusBar.backgroundColorByHexString("#000000");
			}
			if (this.platform.is("cordova")) {
				this.Storage.get("token_fcm").then((val) => {
					if (val != null) {
						if (val.status === "true") {
							let data = {
								status: "false",
								token: "",
							};
							this.Storage.set("token_fcm", data);
						} else {
							this.fcm.getToken().then((token) => {
								let data = {
									status: "true",
									token: token,
								};
								this.Storage.set("token_fcm", data);
							});
						}
					} else {
						this.fcm.getToken().then((token) => {
							let data = {
								status: "true",
								token: token,
							};
							this.Storage.set("token_fcm", data);
						});
					}
				});

				this.fcm.onNotification().subscribe((data) => {
					console.log(data);
					if (data.wasTapped) {
						if (data.tipo == "notificacion_formulario") {
							this.iab.create(data.link, "_system");
						}
					} else {
						this.LocalNotifications.schedule({
							title: data.title,
							text: data.body,
							data: { link: data.link, tipo: data.tipo },
						});
					}
				});

				this.LocalNotifications.on("click").subscribe((res) => {
					if (res.data.tipo == "notificacion_formulario") {
						if (this.platform.is("ios")) {
							location.href = res.data.link;
						} else {
							window.location.href = res.data.link;
						}
					}
				});
			}

			this.splashScreen.hide();
		});
	}

	ngOnInit() {
		//configuracion de ambiente por marca	
		if(environment.APP_MULTIMARCA) {
			const apiUrlForBrand = localStorage.getItem("api_urls");
			if(apiUrlForBrand) this.api.setApiUrls = JSON.parse(apiUrlForBrand); 
			
			const certificateUrlBase = this.up.getRutaBase;
			if(!certificateUrlBase && apiUrlForBrand){
				this.up.genera_rutas();
			}

			this.config_empresa = this.defaultConfigData;
			this.up.setConfig(this.config_empresa);
			this.settingsForBrand();
		} else {
			this.getConfig();
		}
	}

	getConfig() {
		this.api
			.getConfig()
			.then((res) => {
				this.config_empresa = res;				
				this.up.setConfig(this.config_empresa);
				let title = this.getTitle(this.router.routerState, this.router.routerState.root);				
				title.length
					? this.titleService.setTitle(this.config_empresa.titulo + " | " + title[0])
					: this.titleService.setTitle(this.config_empresa.titulo);
				if(this.config_empresa.icono) {
					const favicon = document.getElementById("favicon") as HTMLLinkElement;
					favicon.href = this.config_empresa.icono;
				};
				
				let result_pri = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
					this.config_empresa.color_primario_app
				);
				let result_sec = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
					this.config_empresa.color_secundario_app
				);

				//red,green,blue(primary & secondary)
				let rgb = {
					rp: parseInt(result_pri[1], 16),
					gp: parseInt(result_pri[2], 16),
					bp: parseInt(result_pri[3], 16),
					rs: parseInt(result_sec[1], 16),
					gs: parseInt(result_sec[2], 16),
					bs: parseInt(result_sec[3], 16),
				};

				let rootelement = this.document.documentElement;
				// colores de config
				rootelement.style.setProperty("--primary", this.config_empresa.color_primario_app);
				rootelement.style.setProperty(
					"--ion-color-primary",
					this.config_empresa.color_primario_app
				);
				//color secundario
				rootelement.style.setProperty("--secondary", this.config_empresa.color_secundario_app);
				rootelement.style.setProperty(
					"--ion-color-secondary",
					this.config_empresa.color_secundario_app
				);
				rootelement.style.setProperty(
					"--tertiary",
					this.config_empresa.color_terciario_app
						? this.config_empresa.color_terciario_app
						: this.config_empresa.color_secundario_app
				);
				rootelement.style.setProperty(
					"--ion-color-tertiary",
					this.config_empresa.color_terciario_app
						? this.config_empresa.color_terciario_app
						: this.config_empresa.color_secundario_app
				);
				if (this.config_empresa.template !== "template-inretail") {
					rootelement.style.setProperty(
						"--quaternary",
						this.config_empresa.color_terciario_app ? this.config_empresa.color_terciario_app : this.config_empresa.color_secundario_app
					);
				} 
				
				// variantes
				rootelement.style.setProperty(
					"--primary-focused",
					`rgb(${5.4*rgb.rp/10}, ${6.4*rgb.gp/10}, ${8.8*rgb.bp/10})`
					//`rgb(${6*rgb.rp/10}, ${6*rgb.gp/10}, ${6*rgb.bp/10})`   original
				);
				rootelement.style.setProperty(
					"--primary-light",
					`rgb(${18*rgb.rp/10}, ${17.5*rgb.gp/10}, ${12.2*rgb.bp/10})`
				);
				rootelement.style.setProperty(
					"--primary-light-variant",
					`rgb(${rgb.rp}, ${rgb.gp}, ${rgb.bp},0.3)`
				);
				//color secundario
				rootelement.style.setProperty(
					"--secondary-focused",
					`rgb(${8.6*rgb.rs/10}, ${9.6*rgb.gs/10}, ${9.4*rgb.bs/10})`
				);
				
			})
			.catch((e) => console.log("error:", e));
	}

	settingsForBrand() {
		let title = this.getTitle(this.router.routerState, this.router.routerState.root);
		title.length
			? this.titleService.setTitle(this.config_empresa.titulo + " | " + title[0])
			: this.titleService.setTitle(this.config_empresa.titulo);
		if(this.config_empresa.icono) {
			const favicon = document.getElementById("favicon") as HTMLLinkElement;
			favicon.href = this.config_empresa.icono;
		};
		
		let result_pri = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
			this.config_empresa.color_primario_app
		);
		let result_sec = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
			this.config_empresa.color_secundario_app
		);

		//red,green,blue(primary & secondary)
		let rgb = {
			rp: parseInt(result_pri[1], 16),
			gp: parseInt(result_pri[2], 16),
			bp: parseInt(result_pri[3], 16),
			rs: parseInt(result_sec[1], 16),
			gs: parseInt(result_sec[2], 16),
			bs: parseInt(result_sec[3], 16),
		};

		let rootelement = this.document.documentElement;
		// color primario
		rootelement.style.setProperty(
			"--primary",
			this.config_empresa.color_primario_app
		);
		rootelement.style.setProperty(
			"--ion-color-primary",
			this.config_empresa.color_primario_app
		);
		
		//color secundario
		rootelement.style.setProperty(
			"--secondary",
			this.config_empresa.color_secundario_app
		);
		rootelement.style.setProperty(
			"--secondary-focused",
			`rgb(${8.6*rgb.rs/10}, ${9.6*rgb.gs/10}, ${9.4*rgb.bs/10})`
		);
		rootelement.style.setProperty(
			"--ion-color-secondary",
			this.config_empresa.color_secundario_app
		);
		rootelement.style.setProperty(
			"--tertiary",
			this.config_empresa.color_terciario_app ? this.config_empresa.color_terciario_app : this.config_empresa.color_secundario_app
		);
		rootelement.style.setProperty(
			"--ion-color-tertiary",
			this.config_empresa.color_terciario_app ? this.config_empresa.color_terciario_app : this.config_empresa.color_secundario_app
		);

		if(this.config_empresa.template !== "template-inretail"){
			rootelement.style.setProperty(
				"--quaternary",
				this.config_empresa.color_terciario_app ? this.config_empresa.color_terciario_app : this.config_empresa.color_secundario_app
			);
		}

		// variantes
		rootelement.style.setProperty(
			"--primary-focused",
			`rgb(${5.4*rgb.rp/10}, ${6.4*rgb.gp/10}, ${8.8*rgb.bp/10})`
			//`rgb(${6*rgb.rp/10}, ${6*rgb.gp/10}, ${6*rgb.bp/10})`   original
		);
		rootelement.style.setProperty(
			"--primary-light",
			`rgb(${18*rgb.rp/10}, ${17.5*rgb.gp/10}, ${12.2*rgb.bp/10})`
		);
		rootelement.style.setProperty(
			"--primary-light-variant",
			`rgb(${rgb.rp}, ${rgb.gp}, ${rgb.bp},0.3)`
		);
		//color secundario
		rootelement.style.setProperty(
			"--secondary-focused",
			`rgb(${8.6*rgb.rs/10}, ${9.6*rgb.gs/10}, ${9.4*rgb.bs/10})`
		);
	}


	async PermissionIOS() {
		const wasPermissionGiven: boolean = await this.fcm.requestPushPermission({
			ios9Support: {
				timeout: 10, // How long it will wait for a decision from the user before returning `false`
				interval: 0.3, // How long between each permission verification
			},
		});
	}

	// collect that title data properties from all child routes
	getTitle(state, parent) {
		var data = [];

		if (parent && parent.snapshot.data && parent.snapshot.data.title) {
			data.push(parent.snapshot.data.title);
		}

		if (state && parent) {
			data.push(...this.getTitle(state, state.firstChild(parent)));
		}
		return data;
	}
	async logoutImpersonation() {
		  await this.api.logout_token()
		  .then((res) => {
			this.up.clearStorage();
			this.showImpersonationBar = false;
			this.impersonationBarTitle= "";
			this.router.navigate(["login"]);
			})
			.catch((e) => {
				console.log(e.status, e.url);
			});
	}
	addGoogleAnalyticsScript(){
		if (this.domain != "localhost"){
			let gtagScript: HTMLScriptElement = document.createElement('script');
			gtagScript.async = true;
			gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.GA_TRACKING_ID;
			document.head.prepend(gtagScript);
	
			/** Disable automatic page view hit to fix duplicate page view count  **/
			gtag('config', environment.GA_TRACKING_ID, { send_page_view: false });
		}
	}
}
