import { Injectable } from "@angular/core";
import { LoadingController } from "@ionic/angular";

@Injectable({ providedIn: "root" })
export class LoaderProvider {
	isLoading = false;

	constructor(public loadingCtrl: LoadingController) {}

	async show(message = "") {
		this.isLoading = true;
		return await this.loadingCtrl
			.create({
				spinner: "crescent",
				showBackdrop: true,
				message: message,
			})
			.then((a) => {
				a.present().then(() => {
					// console.log('--- loading presented ---');
					if (!this.isLoading) {
						a.dismiss().then(() => {
							// console.log('--- abort loading ---');
						});
					}
				});
			});
		// await this.loading.present();
	}

	async hide() {
		if (this.isLoading) {
			this.isLoading = false;
			return await this.loadingCtrl.getTop().then((a) => {
				if (a)
					a.dismiss().then(() => {
						// console.log('--- loading dismissed ---');
					});
			});
		}
	}
}
