import { Directive, ElementRef, NgModule } from '@angular/core';

@Directive({
  selector: '[appScrollbarTheme]'
})
export class ScrollbarThemeDirective {

  constructor(el: ElementRef) {

    const stylesheet = `
      @media (min-width: 768px) {
        ::-webkit-scrollbar {
          width: 16px; /* Tamaño del scroll en vertical */
          height: 16px; /* Tamaño del scroll en horizontal */
          -webkit-appearance: none;
        }
        
        ::-webkit-scrollbar-thumb {
          background: #c1c1c1;
          border-radius: 25px;
          border: 4px solid #f1f1f1
        }
        
        /* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #b3b3b3;
        }
        
        /* Cambiamos el fondo cuando esté en active */
        ::-webkit-scrollbar-thumb:active {
          background-color: #999999;
        }
        
        /* Ponemos un color de fondo y redondeamos las esquinas del track */
        ::-webkit-scrollbar-track {
          background: #f1f1f1;
        }
      }
    `;
      
    const styleElmt = el.nativeElement.shadowRoot.querySelector('style');
    if (styleElmt) {
      styleElmt.append(stylesheet);
    } else {
      const barStyle = document.createElement('style');
      barStyle.append(stylesheet);
      el.nativeElement.shadowRoot.appendChild(barStyle);
    }

  }

}

@NgModule({
  declarations: [ ScrollbarThemeDirective ],
  exports: [ ScrollbarThemeDirective ]
})
export class ScrollbarThemeModule {}
