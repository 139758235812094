import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { ApiService } from "src/app/api.service";
import { NotificacionService } from "src/app/service/notificaciones-service/notificacion.service";

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss'],
})
export class NotificationModalComponent implements OnInit {

  @Input() slug_seccion: String
  @Input() slug_accion_completada: String
  @Output() onClose = new EventEmitter();
  movil: Boolean = false;
  mobile: Boolean = window.innerWidth <= 768;
  
  showModal:boolean = true;
  listNotifications: any = null;
  loading: boolean = true;

  constructor(private activatedRoute: ActivatedRoute, public platform: Platform, private modalController: ModalController, private api: ApiService, 
    public router: Router, private ns: NotificacionService,) { 
    if (this.platform.is("cordova") || this.platform.is("mobileweb")) this.movil = true;
  }
  async ngOnInit(){
      let res: any = await this.api.listNotifications();
			this.listNotifications = res;
      this.ns.changeListNotification(false, res)
      this.loading = false;
  } 

  ngAfterContentInit(){

  }

  iconNotification(type){
    switch (type) {
      case 'new-course':
        return 'mdi-book-open-page-variant';
      case 'password-reset':
        return 'mdi-lock-reset'
      case 'password-update':
        return 'mdi-lock-reset'
      case 'ticket-solved':
        return 'mdi-help-circle';
      case 'new-benefit':
          return 'mdi-gift-open';
      case 'new-certificate':
        return 'mdi-certificate';
      case 'new-document':
        return 'mdi-multimedia';
      case 'new-video':
        return 'mdi-file-video'
      case 'new-homework':
          return 'mdi-book-arrow-down';
      case 'email-update':
        return 'mdi-email-fast';
      case 'new-announcement':
        return 'mdi-bullhorn';
      case 'new-meeting':
        return 'mdi-video';
      default:
        return 'mdi-message-star';
    }
  }

  async readNotification(notification){
    await this.api.markAsReadNotification(notification.id, true)
		.then((res) => {
      if(res.success){
        notification.is_read = 1;
        this.ns.changeListNotification(false, this.listNotifications);
        if(notification.path && notification.path != ''){
          if(notification.type.code === 'new-course'){
            let extras: NavigationExtras = {
              queryParams: {
                idN: JSON.stringify(notification.id),
              },		
            };
            this.router.navigateByUrl(`${notification.path}?idN=${notification.id}`);
          } else {
            this.router.navigateByUrl(notification.path);
          }
        }
      }
		})
		.catch((e) => {
		});    
  }

  async pathNotification(notification){
    if(notification.path && notification.path != ''){
      if(notification.type.code === 'new-course'){
        let extras: NavigationExtras = {
          queryParams: {
            idN: JSON.stringify(notification.id),
          },		
        };
        this.router.navigateByUrl(`${notification.path}?idN=${notification.id}`);
      } else {
        this.router.navigateByUrl(notification.path);
      }
    }   
  }

  async hideNotification(notification, index){
    await this.api.hideNotification(notification.id, true)
		.then((res) => {
      if(res.success){
        notification.is_visible = 0;
        const visible = this.listNotifications[index].notifications.some(notification => notification.is_visible );
        if(!visible){
          this.listNotifications.splice(index, 1);
        }
        this.ns.changeListNotification(false, this.listNotifications);
      }
		})
		.catch((e) => {
		});
  }

  closeModal() {
    this.ns.changeShowNotification(false);
  }
}
