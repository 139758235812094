import { Component, OnInit, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-popup-menu',
  templateUrl: './popup-menu.component.html',
  styleUrls: ['./popup-menu.component.scss'],
})
export class PopupMenuComponent implements OnInit {
  @Input() menuProjectedContent: TemplateRef<any>;
  constructor() { }

  ngOnInit() {}
	
}
