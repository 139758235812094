import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Network } from '@ionic-native/network/ngx';
import { BehaviorSubject, Observable} from 'rxjs';
import { Platform } from '@ionic/angular';

/*
  Generated class for the ConexionServiceProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({ providedIn: 'root' })
export class ConexionServiceProvider {

  private hasConnection = new BehaviorSubject(false);

  constructor(private network: Network, private platform: Platform ,public http: HttpClient) {
    // if (this.platform.is('cordova')) {
    //   // on Device
    //   this.network.onConnect().subscribe(() => {
    //       console.log('network was connected Device :-)');
    //       this.hasConnection.next(true);
    //       return;
    //   });
    //   this.network.onDisconnect().subscribe(() => {
    //       console.log('network was disconnected Device :-(');
    //       this.hasConnection.next(false);
    //       return;
    //   });
    // }

    // this.testNetworkConnection();
  }

  public getNetworkType(): string {
    return this.network.type;
}

public getNetworkStatus(): Observable<boolean> {
    return this.hasConnection.asObservable();
}

private getNetworkTestRequest(): Observable<any> {
    return this.http.get('https://jsonplaceholder.typicode.com/todos/1');
}

public async testNetworkConnection() {
    try {
        this.getNetworkTestRequest().subscribe(
        success => {
            // console.log('Request to Google Test  success', success);
                this.hasConnection.next(true);
            return;
        }, error => {
            // console.log('Request to Google Test fails', error);
            this.hasConnection.next(false);
            return;
        });
    } catch (err) {
        console.log('err testNetworkConnection', err);
        this.hasConnection.next(false);
        return;
   }
}

}
